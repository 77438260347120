<template>
  <div class="calendar--business-hours">
    <section
      v-if="allowedAccess"
      class="content box-management"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body">
              <form>
                <div class="row">
                  <select-type
                    class="col-sm-4 col-md-3"
                    name="role"
                    :identifier="'role-selection'"
                    :label="'Rola'"
                    :required="false"
                    :options="roles"
                    :disabled="!rolesLoaded"
                    :empty-first="true"
                    :value="model.roleId"
                    @input="changeRole"
                  />
                </div>
                <div
                  v-if="model.roleId !== null"
                  class="row"
                >
                  <div class="col-sm-12">
                    <h2>Ustawienia online</h2>
                    <list
                      :items="model.businessHours"
                      :empty-element="createEmptyElement()"
                      @modified="forceToSave"
                    >
                      <template
                        slot="element"
                        slot-scope="listItems"
                      >
                        <business-hours-type
                          v-model="listItems.item"
                          :employees="employees"
                          :service="service"
                          @modified="forceToSave"
                        />
                      </template>
                    </list>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <save-data-information
      :show="saveDataInformationVisible"
      @close="saveDataInformationVisible = false"
    />
    <login-modal
      :show="loginVisible"
      :back-url-name="`${service}_calendar_businessHours_listing`"
      @close="loginVisible = false"
      @success="init()"
    />
  </div>
</template>

<script>
import Page from '../../../../share/content/Page'
import api from '../../../../../api'
import List from '../../../../share/form/List'
import SelectType from '../../../../share/form/type/SelectType'
import LoginModal from '../../../share/modal/LoginModal'
import BusinessHoursType from '../../../share/form/type/businessHourType/default/CalendarBusinessHoursType'
import SaveDataInformation from '../../../share/modal/SaveDataInformation'
import moment from 'moment'

export default {
  components: {
    BusinessHoursType,
    SelectType,
    List,
    LoginModal,
    SaveDataInformation
  },
  mixins: [
    Page
  ],
  data () {
    return {
      events: null,
      rolesLoaded: false,
      appraisersLoaded: false,
      businessHoursLoaded: false,
      roles: [],
      employees: [],
      headerTitle: { title: 'Ustawienia online', description: 'Tryb edycji' },
      loginVisible: false,
      allowedAccess: false,
      model: {
        roleId: null,
        businessHours: []
      },
      service: this.$route.meta.acl.service,
      isSaved: true,
      saveDataInformationVisible: false,
      previouslySelectedRole: null
    }
  },
  created () {
    this.getInitEvents()
  },
  mounted () {
    this.loadRoles(this.service)
    this.$nextTick(() => {
      this.loginVisible = true
      this.$events.on(this.events.calendarBusinessHoursSubmit, this.submitData)
    })
  },
  methods: {
    getInitEvents () {
      this.events = {
        calendarBusinessHoursSubmit: `${this.service}:calendar:businessHoursSubmit`
      }
    },
    init () {
      this.allowedAccess = true
    },
    createEmptyElement () {
      return {
        id: null,
        employee: null,
        startAt: null,
        endAt: null,
        cyclicity: this.getCyclicity(0)
      }
    },
    loadData (contractor, roleId) {
      this.loadEmployees(contractor, roleId)
      this.loadBusinessHours(contractor, roleId)
    },
    loadRoles (contractor) {
      this.roles = []
      api.request(contractor, 'get', '/roles')
        .then((response) => {
          const roles = response.data
          for (let c = 0; c < roles.length; ++c) {
            this.roles.push({ label: roles[c].label, value: roles[c].id })
          }
          this.rolesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ról'
          })
        })
    },
    loadEmployees (contractor, role) {
      if (role === '') {
        this.employees = []
        this.businessHoursLoaded = true
        return
      }
      this.employees = []
      api.request(contractor, 'get', `/roles/${role}/users?format=selectOptions`)
        .then((response) => {
          const employees = response.data
          for (let c = 0; c < employees.length; ++c) {
            this.employees.push({ label: employees[c].name, value: employees[c].id })
          }
          this.employeesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować pracowników'
          })
        })
    },
    forceToSave () {
      this.isSaved = false
    },
    changeRole (optionValue) {
      if (!this.isSaved && this.model.businessHours.length > 0) {
        document.getElementsByClassName('role-selection')[0].value = this.previouslySelectedRole
        this.model.roleId = this.previouslySelectedRole
        this.saveDataInformationVisible = true
        return
      }

      this.model.roleId = this.previouslySelectedRole = optionValue

      this.loadData(this.service, this.model.roleId)
    },
    getCyclicity (data) {
      let length = [1, 1, 1, 1, 1, 1, 1]
      let cyclicity = data.toString(2).split('').reverse().map(e => e === '1')
      return length.map((element, index) => {
        return cyclicity.hasOwnProperty(index) && cyclicity[index]
      })
    },
    loadBusinessHours (contractor, role) {
      let intervalLength = 2700000
      if (this.service === 'extpko') {
        intervalLength = 1800000
      }
      if (role === '') {
        this.model.businessHours = []
        this.businessHoursLoaded = true
        return
      }
      api.request(contractor, 'get', `/business-hours?role=${role}&format=admin-list`)
        .then((response) => {
          this.model.businessHours = response.data.map(hour => {
            return {
              id: hour.id,
              startAt: hour.startAt,
              endAt: hour.endAt,
              employee: hour.employee,
              intervals: moment(hour.endAt).diff(hour.startAt) / intervalLength,
              priority: hour.priority,
              cyclicity: this.getCyclicity(hour.cyclicity)
            }
          })
          this.businessHoursLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ustawień online'
          })
        })
    },
    submitData () {
      let submitData = {
        role: this.model.roleId,
        businessHours: []
      }
      for (let i = 0; i < this.model.businessHours.length; ++i) {
        submitData.businessHours.push({
          id: this.model.businessHours[i].id,
          employee: this.model.businessHours[i].employee,
          startAt: this.model.businessHours[i].startAt,
          endAt: this.model.businessHours[i].endAt,
          priority: this.model.businessHours[i].priority,
          cyclicity: parseInt(this.model.businessHours[i].cyclicity.map(element => element ? 1 : 0).reverse().join(''), 2)
        })
      }
      if (submitData.businessHours.filter(hour => !hour.employee).length !== 0) {
        this.$notify({
          type: 'error',
          title: 'Pole pracownik jest wymagane'
        })
        return
      }
      api.request(this.service, 'put', '/business-hours', submitData)
        .then(() => {
          this.isSaved = true
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane zapisane poprawnie'
          })
          this.loadData(this.service, this.model.roleId)
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: `Błąd zapisu danych: ${error.message}`
          })
        })
    }
  }
}
</script>

<style scoped>
h2 {
    font-size: 18px
}
</style>
